import logo from './logo.svg';
import './App.css';

import React, { useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

function App() {
  const [value, setValue] = useState(null);

  function Valor(val){
    setValue(val);
    console.log(val);
    console.log('https://maps.googleapis.com/maps/api/place/details/json?placeid=ChIJoeJPsgiQu0cRgIgqSvxgJwQ&key=AIzaSyCadN8K3_yCdcm9vjrngpEBHMvdydLPt0E')
  }

  return (
    <div>
    <GooglePlacesAutocomplete
      apiKey="AIzaSyCadN8K3_yCdcm9vjrngpEBHMvdydLPt0E"
      selectProps={{value,
        onChange: Valor,
      }}

      onLoadFailed={(error) => (
        console.error("Could not inject Google script", error)
      )}
    />

  </div>
  );
}

export default App;
